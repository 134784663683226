@media only screen 
and (max-width: 2560px){
.background{
    background-image: url(../assets/loginBG.png);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    position: fixed;
    overflow-x: hidden;  
}
}
@media only screen 
and (min-width: 320px){
    .background{
        background-image: url(../assets/loginBG.png);
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        position: fixed;
        overflow-x: hidden;  
    }
}
