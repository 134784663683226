.app-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

table {
  border-collapse: collapse;
  width: 80%;
}

th {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
  font-size: 15px;
}

td {
  padding: 6px;
  font-size: 15px;
}

form {
  display: flex;
  gap: 5px;
}

form td:last-child {
  display: flex;
  justify-content: space-evenly;
}

form  {
  font-size: 28px;
}


/* ======================= MEDIA QUERIES (LARGE DEVICES) ===================================== */

@media screen and (min-width: 1201px) {
  .app__card {
    width: 75%;
    font-size: 10px;
    border-radius: 50px;
    background: rgb(37, 30, 30);
  }
}

/* ======================= MEDIA QUERIES (LARGE DEVICES) ===================================== */

@media screen and (max-width: 1200px) {
  .app-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 25%;
  }

  .app__card {
    width: 75%;
    height: 35%;
  
    font-size: 10px;
    position: relative;
    right: 10rem;
    border-radius: 50px;
    background: rgb(37, 30, 30);
  }


    .hideprop2 {
    display: none;
  }
}

/* ======================= MEDIA QUERIES (SMALL DEVICES) ===================================== */

@media screen and (max-width: 768px) {
  .app-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;
  }

  .app__card {
    width: 75%;
    height: 35%;
  
    font-size: 10px;
    position: relative;
    right: 10rem;
    border-radius: 50px;
    background: rgb(37, 30, 30);
  }

  .hideprop2 {
    display: none;
  }
}
/* =======================MEDIA QUERIES (SMALL DEVICES) ===================================== */

@media only screen and (min-width: 551px) and (max-width: 759px) {
  .app__card {
    width: 40%;
    height: 5%;
    padding: 1rem 0rem;
    margin-top: 5rem;
    position: relative;
    right: 2rem;
    border-radius: 50px;
    background: rgb(37, 30, 30);
  } 

  .app__card > div > img {
    width: 35%;
    height: 25%;
  }

  .hideprop {
    display: none;
    
  }

  .app-container {
    width: 35%;
  }

}

/* =======================MEDIA QUERIES (SMALL DEVICES) ===================================== */

@media screen and (max-width: 550px) {
  .app__card {
    width: 60%;
    height: 5%;
    padding: 1rem 0rem;
    margin-top: 5rem;
    position: relative;
    right: 0.5rem;
    border-radius: 50px;
    background: rgb(37, 30, 30);
  }
  .app__card > div > img {
    width: 30%;
    height: 20%;
  }

  .hideprop {
    display: none;
  }

  .app-container {
    width: 35%;
  }
}

/* ======================= MEDIA QUERIES (SMALL DEVICES) ===================================== */

@media screen and (max-width: 320px) {
  .app__card {
    width: 60%;
    height: 5%;
    padding: 1rem 0rem;
    margin-top: 5rem;
    position: relative;
    right: 0.5rem;
    border-radius: 50px;
    background: rgb(37, 30, 30);
  }
  .app__card > div > img {
    width: 35%;
    height: 25%;
  }

  .hideprop {
    display: none;
  }

  .app-container {
    width: 35%;
  }
}