
.password {
  display: flex;
  flex-direction: column;
}

.valid {
  color: green;
}

.invalid {
  color: red;
}

