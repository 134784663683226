.calendar {
    height: 100vh;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    transition: .5s;
}
  
  .resize {
    width: 84%;
}

@media only screen and (max-width: 800px) {
  .calendar {
    font-size: 0.7em;

    .rbc-btn-group {
      display: none;
    }
  }
}

@media only screen and (max-width: 400px) {
  .calendar {
    font-size: 0.1em;
  }
}
